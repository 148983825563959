import React from "react";
import * as images from "../../assets/furniture";

const FurnitureImage = props => (
  <img
    sizes="100vw"
    {...props}
    src={images[`img${props.reference}`]._1024}
    srcSet={`
${images[`img${props.reference}`]._4096} 4096w, 
${images[`img${props.reference}`]._2048} 2048w, 
${images[`img${props.reference}`]._1024} 1024w, 
${images[`img${props.reference}`]._640} 640w, 
${images[`img${props.reference}`]._320} 320w, 
${images[`img${props.reference}`]._160} 160w
`}
  />
);

export default FurnitureImage;

import React from "react";

const Logo = ({ className }) => (
  <svg
    viewBox="0 0 1300 300"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="title"
    className={className}
  >
    <title>NICO</title>
    <path
      fill="currentColor"
      d="M0 0v300h100v-300h-100zm200 0v300h100v-300h-100zm200 0v300h100v-300h-100zm-300 0v100h100v-100h-100zm500 0v300h100v-300h-100zm100 0v100h200v-100h-200zm0 200v100h200v-100h-200zm300-200v300h100v-300h-100zm100 0v100h200v-100h-200zm0 200v100h200v-100h-200zm100-100v100h100v-100h-100z"
    />
  </svg>
);

export default Logo;

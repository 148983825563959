import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Nav from "../../components/nav/Nav";
import FurnitureImage from "../../components/furniture-image/FurnitureImage";
// import items from "../../data/items";
// import categories from "../../data/categories";
import Context from "../../context";
import itemStyles from "./items.css";
import catStyles from "./categories.css";
import baseStyles from "../../styles/base.css";

// categories.sort((a, b) => a.order - b.order);
// items.sort((a, b) => Number(a.ref) - Number(b.ref));

const Folio = () => (
  <Context.Consumer>
    {({ items, categories, selectedCategoryId, filterItems }) => (
      <div className={baseStyles.container}>
        <Nav />
        <Helmet>
          <title>Nico Furniture | Folio</title>
        </Helmet>
        <ul className={catStyles.catList}>
          <li className={catStyles.cat}>
            <button
              type="button"
              className={
                !selectedCategoryId
                  ? `${catStyles.catLink} ${catStyles.catLinkSelected}`
                  : catStyles.catLink
              }
              onClick={() => filterItems(null)}
            >
              Whole folio
            </button>
          </li>
          {categories.map(cat => (
            <li key={cat.id} className={catStyles.cat}>
              <button
                type="button"
                onClick={() => filterItems(cat.id)}
                className={
                  selectedCategoryId === cat.id
                    ? `${catStyles.catLink} ${catStyles.catLinkSelected}`
                    : catStyles.catLink
                }
              >
                {cat.name}
              </button>
            </li>
          ))}
        </ul>
        <ul className={itemStyles.itemList}>
          {items.map(item => (
            <li className={itemStyles.item} key={item.id}>
              <Link className={itemStyles.itemLink} to={`/folio/${item.ref}`}>
                <FurnitureImage
                  reference={item.ref}
                  className={itemStyles.itemImg}
                  alt={item.ref}
                  sizes="(min-width: 688px) 20vw, (min-width: 1024) calc((100vw - 312px)/4), (min-width: 1200) calc((100vw - 312px)/5), (min-width: 1600px) 215px, 25vw"
                />
                <span className={itemStyles.itemText}>{item.ref}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )}
  </Context.Consumer>
);

export default Folio;

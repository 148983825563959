import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactSwipe from "react-swipe";
import { Helmet } from "react-helmet";
import FurnitureImage from "../../components/furniture-image/FurnitureImage";
import Logo from "../../components/logo/Logo";
import Context from "../../context";
import styles from "./folio-item.css";

class FolioItem extends Component {
  state = {
    detailsVisible: false
  };

  static contextType = Context;

  getCurrentNextAndPrev = () => {
    const { match } = this.props;
    const { items } = this.context;
    const itemInd = items.findIndex(
      item => Number(item.ref) === Number(match.params.id)
    );
    return {
      currItem: items[itemInd],
      nextItem: items[itemInd + 1],
      prevItem: items[itemInd - 1],
      currItemInd: itemInd
    };
  };

  goToNext = () => {
    this.reactSwipeEl.next();
  };

  goToPrev = () => {
    this.reactSwipeEl.prev();
  };

  handleInfoClick = () => {
    this.setState({
      detailsVisible: !this.state.detailsVisible
    });
  };

  handleKeyPress = e => {
    if (e.code === "ArrowRight") {
      this.goToNext();
    }
    if (e.code === "ArrowLeft") {
      this.goToPrev();
    }
    if (e.code === "Escape") {
      this.props.history.push(`/folio`);
    }
    if (e.code === "KeyI") {
      this.setState({
        detailsVisible: !this.state.detailsVisible
      });
    }
  };

  slideChange = ind => {
    const { items } = this.context;
    const { match } = this.props;
    if (Number(items[ind].ref) !== Number(match.params.id)) {
      this.props.history.push(`/folio/${items[ind].ref}`);
    }
    this.setState({
      detailsVisible: false
    });
  };

  componentDidMount() {
    const { items } = this.context;
    const { match } = this.props;
    // const itemInd = items.findIndex(
    //   item => Number(item.ref) === Number(match.params.id)
    // );
    // this.reactSwipeEl.slide(itemInd, 0);
    document.body.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeyPress);
  }

  componentDidUpdate() {
    const { items } = this.context;
    const { match } = this.props;
    const itemInd = items.findIndex(
      item => Number(item.ref) === Number(match.params.id)
    );
    const currSlidePos = this.reactSwipeEl.getPos();
    if (currSlidePos !== itemInd) {
      this.reactSwipeEl.slide(itemInd, 0);
    }
  }

  render() {
    const { items } = this.context;
    const { currItem, prevItem, nextItem, currItemInd } = this.getCurrentNextAndPrev();

    return (
      <div className={styles.container}>
        <Helmet>
          <title>Nico Furniture | Folio | {currItem.ref}</title>
        </Helmet>
        <ReactSwipe
          className={styles.itemsContainer}
          swipeOptions={{
            continuous: false,
            transitionEnd: this.slideChange,
            startSlide: currItemInd
          }}
          ref={el => (this.reactSwipeEl = el)}
        >
          {items.map(item => (
            <div
              className={styles.itemContainer}
              key={item.ref}
              data-class="item-wrapper"
              aria-hidden={item.ref !== currItem.ref}
            >
              <div
                className={
                  this.state.detailsVisible && item.ref === currItem.ref
                    ? `${styles.details} ${styles.detailsVisible}`
                    : styles.details
                }
              >
                <Logo className={styles.logo} />
                <h1 className={styles.title}>Ref NICO{item.ref}</h1>
                <p>{item.description}</p>
                <div>
                  <h2 className={styles.title}>H × W × D</h2>
                  <p>
                    {item.dimension1} mm × {item.dimension2} mm ×{" "}
                    {item.dimension3} mm
                  </p>
                </div>
                <p>Available in any size and finish to commission</p>
              </div>

              <div className={styles.imageContainer}>
                <FurnitureImage
                  reference={item.ref}
                  className={styles.image}
                  alt={item.ref}
                  sizes={item.ref === currItem.ref ? "100vw" : "160px"}
                />
              </div>
            </div>
          ))}
        </ReactSwipe>
        <button
          onClick={this.handleInfoClick}
          type="button"
          className={`${styles.info} ${styles.control}`}
        >
          <span>Info</span>
        </button>
        <Link to="/folio" className={`${styles.exit} ${styles.control}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
            <path d="m3,0l21,21 21-21 3,3-21,21 21,21-3,3-21-21-21,21-3-3 21-21-21-21 3-3Z" />
          </svg>
        </Link>
        {nextItem && (
          <button
            type="button"
            className={`${styles.next} ${styles.control}`}
            onClick={this.goToNext}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 48">
              <path d="m0,3l3-3 24,24-24,24-3-3 21-21-21-21Z" />
            </svg>
          </button>
        )}
        {prevItem && (
          <button
            type="button"
            className={`${styles.prev} ${styles.control}`}
            onClick={this.goToPrev}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 48">
              <path d="m24,0l3,3-21,21 21,21-3,3-24-24 24-24Z" />
            </svg>
          </button>
        )}
      </div>
    );
  }
}

export default FolioItem;

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logo/Logo";
import styles from "./nav.css";

const Nav = () => (
  <nav className={styles.container}>
    <h1 className={styles.logoHolder}>
      <Link to="/" className={styles.logoLink}>
        <Logo className={styles.logo} />
      </Link>
    </h1>
    <Link to="/folio" className={styles.item}>
      Folio
    </Link>
    <Link to="/contact" className={styles.item}>
      Contact
    </Link>
    <Link to="/about" className={styles.item}>
      About
    </Link>
  </nav>
);

export default Nav;

import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../../components/nav/Nav";
import * as portrait from "../../assets/nico_villeneve";
import styles from "./about.css";
import baseStyles from "../../styles/base.css";

const About = () => (
  <div className={`${styles.container} ${baseStyles.container}`}>
    <Helmet>
      <title>Nico Furniture | About</title>
    </Helmet>
    <Nav />
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <img
          sizes="(min-width: 640px) 312px, 100vw"
          alt="Nico Villeneuve"
          src={portrait._1024}
          srcSet={`${portrait._2048} 2048w, ${portrait._1024} 1024w, ${portrait._640} 640w`}
        />
      </div>
      <div className={styles.textContainer}>
        <p>
          Nico Villeneuve designs exceptional contemporary furniture to
          commission. Clarity of vision is combined with a sound application of
          craftsmanship to create unique pieces for high end residential
          projects.
        </p>
        <p>
          Nico trained as a furniture designer and maker with John Makepeace at
          Parnham House in England, and also worked for many years as an
          interior designer. Concentrating now solely on furniture, he aims to
          provide a personal service to designers and private clients looking
          for pieces with integrity and architectural presence.
        </p>
        <p>
          The simplicity of form relies on careful selection of materials
          combined with impeccable detailing to result in objects of lasting
          value. It is not enough that they look good in a photo, they must also
          feel wonderful to the touch. Undersides and backs are finished smooth
          to the same high standard as tops and fronts; the edges of veneered
          surfaces are often inlaid for protection, and made more comfortable
          for close contact with a soft radius. This level of quality is only
          achievable in the best custom made furniture, and will reward those
          who appreciate it with much pleasure in use.
        </p>
        <p>
          A selection of recent projects can be seen in the portfolio section.
          Please contact Nico to discuss any ideas for commissioning similar
          pieces.
        </p>
      </div>
    </div>
  </div>
);

export default About;

import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./styles/reset.css";
import "./styles/base.css";
import itemList from "./data/items";
import categoryList from "./data/categories";
import About from "./views/about/About";
import Contact from "./views/contact/Contact";
import Folio from "./views/folio/Folio";
import FolioItem from "./views/folio-item/FolioItem";
import Home from "./views/home/Home";
import Context from "./context";

categoryList.sort((a, b) => a.order - b.order);
itemList.sort((a, b) => Number(a.ref) - Number(b.ref));

class App extends Component {
  state = {
    categories: categoryList,
    items: itemList,
    selectedCategoryId: null
  };

  filterItems = categoryId => {
    this.setState({
      items: categoryId
        ? itemList.filter(item => item.category_id === categoryId)
        : itemList,
      selectedCategoryId: categoryId ? categoryId : null
    });
  };

  render() {
    return (
      <Context.Provider
        value={{ filterItems: this.filterItems, ...this.state }}
      >
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/folio/:id" component={FolioItem} />
            <Route path="/folio" exact component={Folio} />
          </Switch>
        </BrowserRouter>
      </Context.Provider>
    );
  }
}

export default App;
